<template>
    <img :src="image"/>
    <h1>Coming Soon...</h1>
</template>


<script>
export default {
    name: "ComingSoon",
    data() {
        return {
            image: require("@/assets/kac-banner.png")
        }
    }
}
</script>

<style scoped>
img {
    max-height: 500px;
}
</style>