<template>
  <div class="page-content">
    <router-view />
  </div>
</template>

<script>
// import AboutUs from "@/components/AboutUs.vue";
// import ComingSoon from "./components/ComingSoon.vue";

export default {
  name: "App",
  // components: {
  //   ComingSoon,
  // },
};
</script>

<style>
/* Ensure no margin and padding for the entire document */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* Full height for the HTML and body */
  background-color: #0c0c0c;
}

/* Ensure the app container takes the full viewport height */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff; /* White text color for better contrast */
  min-height: 100vh; /* Ensure the app takes the full height of the viewport */
  display: flex;
  flex-direction: column; /* Allows stacking of elements */
}

.page-content {
  margin-top: 36px;
}

/* Base button styles */
button {
  font-family: inherit; /* Inherit font from parent */
  font-size: 16px; /* Adjust size as needed */
  padding: 10px 20px; /* Padding for a comfortable click area */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners for modern look */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions for hover and active states */
  color: #ffffff; /* White text for contrast */
  background-color: #007bff; /* Primary button color */
}

/* Hover state */
button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

/* Active state */
button:active {
  transform: scale(0.95); /* Slight scale down effect when clicked */
}

/* Disabled state */
button:disabled {
  background-color: #555; /* Greyed out background for disabled buttons */
  cursor: not-allowed; /* Indicate that it's not clickable */
}

/* Secondary button style */
.button-secondary {
  background-color: #6c757d; /* Secondary color */
}

.button-secondary:hover {
  background-color: #5a6268; /* Darker shade for secondary button on hover */
}

/* Link styles */
a {
  color: #007bff; /* Primary color for links */
  text-decoration: none; /* Remove underline for a cleaner look */
  transition: color 0.3s, text-shadow 0.3s; /* Smooth transition for hover effect */
}

/* Hover state */
a:hover {
  color: #0056b3; /* Darker shade for hover effect */
  text-shadow: 0 0 5px rgba(0, 123, 255, 0.6); /* Subtle glow effect on hover */
}

/* Active state */
a:active {
  color: #003d80; /* Even darker shade when the link is clicked */
}

/* Visited links */
a:visited {
  color: #6c757d; /* Color for visited links, a bit muted for contrast */
}
</style>
