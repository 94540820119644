import { createRouter, createWebHistory } from "vue-router";
import ComingSoon from "@/views/ComingSoon.vue";
import NotFound from "@/views/NotFound.vue";
import HomeView from "@/views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: ComingSoon,
    meta: { title: "Coming Soon... | KillAllChickens" },
  },
  {
    path: "/test",
    name: "TestPage",
    component: HomeView,
    meta: { title: "Landing Page | KillAllChickens" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: { title: "Page Not Found | KillAllChickens" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
