<template>
    <div class="grid-container">
        <div class="page" v-for="(page, index) in pages" :key="index" @click="page.whenClicked()">
            <img class="page-image" v-if="page.img !== null" :src="page.img" alt="page image"/>
            <div class="page-title">{{ page.title }}</div>
            <div class="page-desc">{{ page.desc }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeView",
    data() {
        return {
            subdomainBase: "killallchickens.org",
            pages: [
                {
                    title: "Courses!",
                    desc: "Want to learn about computers or programming? Take a look at our courses. They are all free!",
                    img: null,
                    whenClicked: () => this.openSubdomain("learn"),
                },
                {
                    title: "Socials!",
                    desc: "Connect with me on other platforms!",
                    img: null,
                    whenClicked: () => this.openSubdomain("links"),
                }
            ]
        };
    },
    methods: {
        openSubdomain(subdomain) {
            window.open(`https://${subdomain}.${this.subdomainBase}`, "_blank");
        },
        openLink(url) {
            window.open(url, "_blank");
        }
    }
};
</script>

<style scoped>
/* Global background and text colors */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    padding: 20px;
    background-color: #0c0c0c;
    color: whitesmoke;
}

/* Page card styling */
.page {
  background-color: #1c1c1c;
  color: whitesmoke;
  padding: 30px 20px;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #cc0000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  min-height: 120px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.page:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

/* Page title styling */
.page-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f2f2f2;
  margin-bottom: 10px;
}

/* Page description styling */
.page-desc {
  font-size: 1rem;
  color: #e0e0e0;
  line-height: 1.5;
  margin-top: 5px;
}

/* Image styling */
.page-image {
  max-width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
}

/* Responsive grid adjustments */
@media (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
